import type { App } from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
/**
 * https://github.com/championswimmer/vuex-persist
 * 1) create and import module with its store interface
 */
import game, { type GameStore } from '@/storeModules/game'

/**
 * 2) add a new store interface of module  to the interface of Vuex store state
 */
export interface State {
  game: GameStore
}

export default {
  install: (Vue: App) => {
    /**
     * 3) create VuexPersistence plugin instance and map it with  store module/modules
     */
    const vuexLocal = new VuexPersistence<State>({
      storage: window.localStorage,
      modules: ['game']
    })

    /**
     * 4) register Vuex with store modules and VuexPersistence plugins;
     */
    const store = new Vuex.Store<State>({
      strict: import.meta.env.MODE !== 'production', // https://github.com/championswimmer/vuex-persist#support-strict-mode
      modules: {
        game
      },
      plugins: [vuexLocal.plugin]
    })
    Vue.use(store)
  }
}
