import { tools } from '@/compositions/tools'
import { LevelStatus } from '@/types/enums'

const { toHumanNumber, numberFormat } = tools
export interface Token {
  id: string
  name: string
  token: string // is set when is valid for jwt_decode()
  valid: string
  __token: string
  [key: string]: string
}
export interface GameStore {
  tokens?: { [key: string]: Token }
  loyalty: Games.GamesLoyalty
  logBans?: { [key: string]: string }
  cart?: { [key: string]: Games.Offer[] }
  cartMinVal?: { [key: string]: number }
}

function getGameCartMap(state: GameStore, game: Games.GameKeyType) {
  return Object.fromEntries((state.cart?.[game] || []).map((item, index) => [item.id, index]))
}
function getGameCartSumVal(state: GameStore, game: Games.GameKeyType) {
  return (state.cart?.[game] || []).reduce(
    (sum, offer) => sum + (offer?.discount?.value || offer?.price?.value || 0) * (offer?.qty || 1),
    0
  )
}
function getGameCartMinVal(state: GameStore, game: Games.GameKeyType) {
  if (state.tokens?.[game]?.id === String(state.loyalty?.[game]?.id) && state.loyalty?.[game]?.id) {
    const data = state.loyalty[game] || null
    if (data) {
      return data.minPurchaseCur || 0
    }
  }
  return 0
}

const cartCapacityValue = 6

export default {
  namespaced: true,
  state: (): GameStore => ({
    tokens: {},
    loyalty: {},
    logBans: {},
    cart: {},
    cartMinVal: {}
  }),
  // call: $store?.getters?.['namespaced_path']
  getters: {
    token: (state: GameStore) => (game: string) => {
      // $store.getters['game/token'](game);
      return state.tokens?.[game] || null
    },
    tokens: (state: GameStore) => {
      return state.tokens || {}
    },
    loyalty:
      (state: GameStore) =>
        (gameKey: Games.GameKeyType): Games.Loyalty | null => {
          if (state.tokens?.[gameKey]?.id === String(state.loyalty?.[gameKey]?.id) && state.loyalty?.[gameKey]?.id) {
            return state.loyalty[gameKey] || null
          }
          return null
        },
    logBan: (state: GameStore) => (game: Games.GameKeyType) => {
      return state.logBans?.[game]
    },
    gameCart:
      (state: GameStore) =>
        (game: Games.GameKeyType): Games.Offer[] => {
          return state.cart?.[game] || []
        },
    gameCartMap:
      (state: GameStore) =>
        (game: Games.GameKeyType): Record<string | number, number> => {
          return getGameCartMap(state, game)
        },
    gameCartMinVal:
      (state: GameStore) =>
        (game: Games.GameKeyType): number => {
          return getGameCartMinVal(state, game)
        },
    gameCartSumVal:
      (state: GameStore) =>
        (game: Games.GameKeyType): number => {
          return getGameCartSumVal(state, game)
        },
    gameCartSumValHuman:
      (state: GameStore) =>
        (game: Games.GameKeyType): string => {
          const sum = getGameCartSumVal(state, game)
          if (!sum) {
            return ''
          }
          return `${state.loyalty[game]?.cur || ''} ${numberFormat(sum || 0)}`
        },
    gameCartPaymentAvailable:
      (state: GameStore) =>
        (game: Games.GameKeyType, addedOfferValue = 0): boolean => {
          return getGameCartSumVal(state, game) + addedOfferValue >= getGameCartMinVal(state, game)
        },
    cartCapacity: () => {
      return cartCapacityValue
    }
  },
  // call: $store?.commit?.('namespaced_path')
  mutations: {
    setToken(state: GameStore, [game, token]: [game: string, token: Token]) {
      if (state.tokens) {
        state.tokens[game] = token
      }
    },
    clearTokens(state: GameStore) {
      state.tokens = {}
    },
    logOut(state: GameStore, game: string) {
      if (state?.tokens?.[game]) {
        delete state.tokens[game]
        if (state.cart && game) {
          state.cart[game] = []
        }
      }
    },
    setLoyalty(state: GameStore, data: [gameKey: Games.GameKeyType, apiLayaltyData: Games.Lax<Games.ApiLoyaltyData>]) {
      const [gameKey, apiLayaltyData] = data
      if (apiLayaltyData?.id && String(apiLayaltyData.id) === state?.tokens?.[gameKey]?.id) {
        state.loyalty[gameKey] = {
          id: apiLayaltyData?.id || 0,
          gcoins: apiLayaltyData?.gcoins || 0,
          energy: apiLayaltyData?.energy || 0,
          gems: apiLayaltyData?.gems || 0,
          loyalPct: apiLayaltyData?.loyalPct || 0,
          loyalStatus: apiLayaltyData?.loyalStatus || 0,
          loyalStatusNext: apiLayaltyData?.loyalStatusNext || 0,
          gcoinsHuman: toHumanNumber(apiLayaltyData?.gcoins || 0),
          energyHuman: toHumanNumber(apiLayaltyData?.energy || 0),
          gemsHuman: toHumanNumber(apiLayaltyData?.gems || 0),
          levelStatus: (LevelStatus[apiLayaltyData?.loyalStatus || 0] ?? 'SILVER').toLowerCase(),
          nextLevelStatus: (LevelStatus[apiLayaltyData?.loyalStatusNext || 0] ?? 'SILVER').toLowerCase(),
          cur: apiLayaltyData?.cur || '',
          minPurchaseCur: apiLayaltyData?.minPurchaseCur || 0,
          minPurchaseCurHuman: `${apiLayaltyData?.cur || ''} ${numberFormat(apiLayaltyData?.minPurchaseCur || 0)}`
        }
      } else {
        delete state.loyalty[gameKey]
      }
    },
    setLogBan(state: GameStore, [gameKey, until]: [Games.GameKeyType, string]) {
      if (state.logBans) {
        state.logBans[gameKey] = until
      }
    },
    addToGameCart(state: GameStore, [offer]: [offer: Games.Offer]) {
      const cartMap = getGameCartMap(state, offer.gameKey)
      const copyOffer = tools.cloneDeep(offer)
      if (state.cart && copyOffer.id) {
        if (!state.cart[copyOffer.gameKey]) {
          state.cart[copyOffer.gameKey] = []
        }
        copyOffer.qty = 1
        if (state.cart[copyOffer.gameKey][cartMap[copyOffer.id]] !== undefined) {
          const currentQty = state.cart[copyOffer.gameKey][cartMap[copyOffer.id]].qty ?? 1
          if (copyOffer.allowQty && (copyOffer.purchaseLimit ?? 1) > currentQty) {
            state.cart[copyOffer.gameKey][cartMap[copyOffer.id]].qty = currentQty + 1
          }
        } else if (state.cart[copyOffer.gameKey].length < cartCapacityValue) {
          state.cart[copyOffer.gameKey].push(copyOffer)
        }
      }
    },
    /**
     * if index it is updated from cart lvl;
     * if index === undefined it is updated after refresh offer list from api;
     * offerData have to contains id;
     */
    updateGameCartOffer(
      state: GameStore,
      [game, offerData, index]: [game: string, offerData: Games.Offer, index: number | undefined]
    ) {
      const cartIndex = index ?? getGameCartMap(state, offerData.gameKey)?.[offerData?.id ?? ''] ?? -1
      if (
        state.cart?.[game]?.[cartIndex] &&
        state.cart[game][cartIndex]?.id &&
        state.cart[game][cartIndex].id === offerData?.id
      ) {
        const copyOfferData = tools.cloneDeep(offerData)
        state.cart[game][cartIndex] = { ...state.cart[game][cartIndex], ...copyOfferData }
      }
    },
    clearGameCart(state: GameStore, [game]: [game: string]) {
      if (state.cart && game) {
        state.cart[game] = []
      }
    },
    removeFromGameCart(state: GameStore, [game, index]: [game: string, index: number]) {
      if (state.cart?.[game]?.[index] !== undefined) {
        state.cart[game].splice(index, 1)
      }
    },
    setCartGameMinVal(state: GameStore, [gameKey, minVal]: [Games.GameKeyType, number]) {
      if (state.cartMinVal) {
        state.cartMinVal[gameKey] = minVal
      }
    }
  },
  // actions are async; call: $store?.dispatch?.('namespaced_path')
  actions: {}
}
