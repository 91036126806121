import type { App } from 'vue'
import axios from 'axios'

export default {
  install: (Vue: App) => {
    const instance = axios.create()
    instance.interceptors.response.use(
      response => response,
      (error) => {
        if (error?.response?.status === 401) {
          return Promise.reject(error)
        } else if (error?.response?.status > 400) {
          Vue.config.globalProperties.$notify({
            title: 'Error',
            type: 'warning',
            customClass: 'child-inherit-colors text-red-600 z-[999999]',
            message: error?.response?.data?.msg || 'Oops something went wrong',
            duration: 9000
          })
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(false)
        }
        return Promise.reject(error)
      }
    )
    Vue.config.globalProperties.$axios = instance
  }
}
