import type { App as AppType, InjectionKey } from 'vue'
import type { RouteLocation } from 'vue-router'
import { optGtag } from '@/components/layout/ManageCookies/ManageCookiesCompose'

let App: AppType | null = null

/**
 * visible in GlobalProperties
 */
const utilsGlobalProperties = {
  getAppRefer: () => {
    return App
  },
  getAppGlobalProperties: () => {
    return App?.config?.globalProperties || null
  }
}

/**
 * visible in utilsPlugin (imported globally by AutoImport package)
 */
function nextLoopEvent(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback)
  if (!resolved) {
    throw new Error(`Could not resolve ${key.description}`)
  }
  return resolved
}

function getFullRouteData(route: VueRouteLocation, silent = false): undefined | RouteLocation {
  try {
    const globalProperties = utilsGlobalProperties.getAppGlobalProperties()
    if (!globalProperties) {
      return undefined
    }
    return typeof route === 'string'
      ? globalProperties.$router.resolve({ name: route })
      : globalProperties.$router.resolve(route)
  } catch (e) {
    if (!silent) {
      console?.error?.(e, route)
    }
  }
}

function isNotNullGuard<T>(data: T | null): data is T {
  return (data as T) !== null
}

function siteGtag() {
  if (!optGtag.value) {
    return null
  }
  return App?.config?.globalProperties?.$gtag || null
}

const utilsPlugin = {
  ...utilsGlobalProperties,
  nextLoopEvent,
  injectStrict,
  getFullRouteData,
  isNotNullGuard,
  siteGtag
}

/**
 * require import
 */

/**
 * register  utilsGlobalProperties as $utils
 */
export default {
  install: (Vue: AppType) => {
    Vue.config.globalProperties.$utils = utilsGlobalProperties
    App = Vue
  }
}
/**
 * export utilsPlugin (imported globally by AutoImport package)
 * rest of exports require manual import
 */
export { utilsPlugin }
