export const offerDialog = ref<{ visible: boolean, offer: Games.Offer | null }>({
  visible: false,
  offer: null
})

export function showOfferUrl() {
  if (!offerDialog.value.offer)
    return
  const router = utilsPlugin.getAppGlobalProperties()?.$router
  if (router) {
    router.replace({
      query: {
        offerGameId: gamesUtils.getGameSettings(offerDialog.value?.offer?.gameKey)?.id,
        offerId: offerDialog.value.offer.id
      },
      hash: '#'
    })
  }
}

export function clickRouteToOffer(ev: MouseEvent, offer: Games.Offer) {
  if ((offer?.expireSec || 0) < 0 || (!!offer?.moGroupId && !!offer?.isBought) || !!offer?.moIsLocked) {
    ev.preventDefault()
    ev.stopPropagation()
    return false
  }
  if (!(ev.metaKey || ev.ctrlKey)) {
    offerDialog.value.visible = true
    offerDialog.value.offer = offer
    ev.preventDefault()
    ev.stopPropagation()
    showOfferUrl()
  }
}
