export interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>
}

export const deferredPrompt = ref<BeforeInstallPromptEvent | null>(null)
export const showInstallPrompt = ref(false)
export const disableInstallPrompt = ref(false)
export const canInstallAsApp = ref(false)
export const supportedInstallAsApp = ref(false)
export const showConfirmDisableInstallPrompt = ref(false)
export const checkBoxDisableInstallPrompt = ref(false)
