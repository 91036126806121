import type { Token } from '@/storeModules/game'
import type { App } from 'vue'
import { loadingPage } from '@/components/layout/PageLoader/PageLoaderCompose'
import * as Sentry from '@sentry/vue'
import { useHead } from '@vueuse/head'
import copy from 'clipboard-copy'
import { ElMessage } from 'element-plus'
import { jwtDecode } from 'jwt-decode'
import VueGtag from 'vue-gtag'
import {
  createRouter,
  createWebHistory,
  type NavigationGuard,
  type RouteLocation,
  type RouteLocationNormalized,
  type Router,
  type RouteRecordRaw
} from 'vue-router'
import pagesRoutes from './routes'

const MainLayout = () => import('@/layouts/MainLayout.vue')

/**
 *  @description set basic meta tags of page;
 *  @field title - title and og:title;
 *  @field description - description and og:description
 *  @field image - og:image
 *  @field path - og:url and canonical
 *  @field defaultVariables - default values of variables used by templates string
 *  @defaultVariables templates strings can be used in the title and description fields; eg ':) {titlePage}';
 *                    variables are passed from this field (@defaultVariables) and merged with route  params and queries
 */
export interface SeoBasicData {
  title?: string
  description?: string
  image?: string
  path?: string
  defaultVariables?: Record<string, unknown>
  bodyClass?: string
}

/**
 * @field ssg - used by vite-ssg
 *        name - name route (can be template strings)
 *        path - path route (can be template strings)
 *        image - og:image
 *        defaultVariables - variables for template strings; merged with route defaultVariables
 */
interface PageMetaData {
  meta: {
    basicSeo?: SeoBasicData
    bodyClass?: string
  }
  ssg?: {
    name: string
    path: string
    image?: string
    defaultVariables?: Record<string, unknown>
  }[]
}
export type RoutesData = RouteRecordRaw & PageMetaData

export function replaceTemplate(template: string, data: Record<string, unknown> | undefined) {
  if (data) {
    const patternVariables = /\{\s*(\w+)\s*\}/g // eg {property_name}
    return template.replace(patternVariables, (_, token) => String(data[token] || `{${token}}`))
  }
  return template
}

export function isString(value: unknown): value is string {
  return typeof value === 'string'
}

const reactiveHeadData = {
  title: ref(''),
  description: ref(''),
  image: ref(''),
  path: ref(''),
  bodyClass: ref(''),
  isInit: false
}

export function initReactiveSeoData() {
  useHead({
    title: reactiveHeadData.title,
    meta: [
      {
        property: 'og:title',
        content: reactiveHeadData.title
      },
      {
        name: 'description',
        content: reactiveHeadData.description
      },
      {
        property: 'og:description',
        content: reactiveHeadData.description
      },
      {
        property: 'og:url',
        content: reactiveHeadData.path
      },
      {
        property: 'og:image',
        content: reactiveHeadData.image
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: reactiveHeadData.path
      }
    ],
    bodyAttrs: {
      class: reactiveHeadData.bodyClass
    }
  })
}
export function setBasicSeoData(data: SeoBasicData, variables: Record<string, unknown> | undefined = undefined) {
  if (isString(data.title)) {
    reactiveHeadData.title.value = replaceTemplate(data.title, variables)
  }
  if (isString(data.description)) {
    reactiveHeadData.description.value = replaceTemplate(data.description, variables)
  }
  if (isString(data.path)) {
    reactiveHeadData.path.value = `${import.meta.env.VITE_HOST || ''}${data.path}`
  }
  if (isString(data.image)) {
    reactiveHeadData.image.value = data.image
  }
  if (isString(data.bodyClass)) {
    reactiveHeadData.bodyClass.value = data.bodyClass
  }
}

let vue: App
let vueRouter: Router
function guardBeforeEnter(to: RouteLocation) {
  if (vue) {
    const isDeepLinkToken = !!to?.query?.token?.length && !!to?.query?.gameKey
    if (to.fullPath.substring(0, 11) === '/deep-link/' || isDeepLinkToken) {
      const gameKey: Games.GameKeyLax = to?.query?.gameKey as Games.GameKeyType
      gamesUtils.setGameToken(gameKey, null)
      const buyOffer = JSON.parse(String(localStorage?.getItem?.('buyOffer'))) || {}
      const query = { ...to.query }
      // http://localhost:8100/game/dev-slash-and-roll?route=game-page&gameKey=slashAndRoll&offerId=offer211&token=26db5fe8633a25eb4abc36e2fc005967
      buyOffer.deepLink = true
      if (isDeepLinkToken) {
        let decodedJwt = null
        try {
          decodedJwt = jwtDecode(String(to.query.token))
        } catch (err) {
          localStorage?.setItem?.('deep-link', to?.fullPath)
          copy(to?.fullPath)
          console?.error?.('not decoded jwt', err, to.query.token)
        }
        const tokenData = {
          token: decodedJwt ? String(to?.query?.token) : '',
          ...(decodedJwt || {}),
          __token: String(to?.query?.token)
        }
        delete query.token
        gamesUtils.setGameToken(gameKey, tokenData as Token)

        if (tokenData.token && !query?.offerId) {
          setTimeout(() => {
            ElMessage({
              message: 'Logged in',
              type: 'success',
              customClass: 'left-auto right-10',
              duration: 3000
            })
          }, 500)
        }
      }
      if (!buyOffer?.gameKey || buyOffer?.gameKey !== query?.gameKey || buyOffer?.offer?.id !== query?.offerId) {
        buyOffer.gameKey = query?.gameKey
        buyOffer.offer = null
      }

      localStorage?.setItem?.('buyOffer', JSON.stringify(buyOffer))

      delete query.route
      delete query.gameKey
      delete query.offerId

      switch (to?.query?.route) {
        case 'game-page':
          return { name: 'game-page', params: { gameID: gamesUtils?.list?.[gameKey]?.id }, query: { ...query } }
        case 'game-product-page':
          return {
            name: 'game-product-page',
            params: { gameID: gamesUtils?.list?.[gameKey]?.id, offerID: to?.query?.offerId || '' },
            query: { ...query }
          }
        default:
          return { name: to?.query?.route, query: { ...query } }
      }
    }

    return true
  }
  return true
}
function getSkeletonRoutes(): RouteRecordRaw[] {
  return [
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/pages/404/ErrorPage.vue'),
      beforeEnter: [guardBeforeEnter as NavigationGuard]
    },
    {
      path: '/',
      name: 'home',
      component: MainLayout,
      beforeEnter: [guardBeforeEnter as NavigationGuard],
      children: []
    }
  ]
}

function routerSetBasicSeoDataCallBack(to: RouteLocationNormalized) {
  const data: PageMetaData = to
  if (!reactiveHeadData.isInit) {
    initReactiveSeoData()
    reactiveHeadData.isInit = true
  }
  const resetData: SeoBasicData = {
    title: '',
    description: '',
    image: '',
    path: data.meta?.basicSeo?.path || to.path || '',
    bodyClass: ''
  }
  setBasicSeoData(
    { ...resetData, ...data.meta.basicSeo, bodyClass: data.meta.bodyClass ?? '' },
    { ...data.meta?.basicSeo?.defaultVariables, ...to.query, ...to.params }
  )
}

export function routerSsgSetBasicSeoDataCallBack(to: RouteLocationNormalized) {
  const data: PageMetaData = to
  useHead({
    title: replaceTemplate(data.meta.basicSeo?.title ?? '', data.meta.basicSeo?.defaultVariables ?? {}),
    meta: [
      {
        property: 'og:title',
        content: replaceTemplate(data.meta.basicSeo?.title ?? '', data.meta.basicSeo?.defaultVariables ?? {})
      },
      {
        name: 'description',
        content: replaceTemplate(data.meta.basicSeo?.description ?? '', data.meta.basicSeo?.defaultVariables ?? {})
      },
      {
        property: 'og:description',
        content: replaceTemplate(data.meta.basicSeo?.description ?? '', data.meta.basicSeo?.defaultVariables ?? {})
      },
      {
        property: 'og:url',
        content: `${import.meta.env.VITE_HOST || ''}${to.path}`
      },
      {
        property: 'og:image',
        content: data.meta.basicSeo?.image ?? ''
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: `${import.meta.env.VITE_HOST || ''}${to.path}`
      }
    ],
    bodyAttrs: {
      class: data.meta.bodyClass ?? ''
    }
  })
}

function initRouter() {
  const skeletonRoutes = getSkeletonRoutes()
  skeletonRoutes[1].children = pagesRoutes()
  skeletonRoutes.push(
    ...[
      {
        path: '/gamesture-terms-of-service.pdf',
        redirect: '/terms-of-service'
      },
      {
        path: '/gamesture-terms-of-service.html',
        redirect: '/terms-of-service'
      },
      {
        path: '/gamesture-privacy-policy.pdf',
        redirect: '/privacy-policy'
      },
      {
        path: '/gamesture-privacy-policy.html',
        redirect: '/privacy-policy'
      }
    ]
  )

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: skeletonRoutes,
    scrollBehavior(to) {
      if (to.hash) {
        return false
      }
      return { top: 0, behavior: 'smooth' }
    }
  })

  /**
   *  start loaderPage
   */
  router.beforeEach((to, from) => {
    if (from.path && to.path !== from.path) {
      loadingPage.value = true
    }
  })

  /**
   * Register seo, end finish loaderPage
   */
  router.afterEach((to) => {
    loadingPage.value = false
    routerSetBasicSeoDataCallBack(to)
  })
  /**
   * Register deploy-version checker on router error when SSG;
   * (preventing failures due to the implementation of a new version)
   */
  router.onError((_, to) => {
    const isSsr = import.meta.env.SSR
    const isDevMode = import.meta.env.MODE === 'development'
    if (!isSsr && !isDevMode) {
      let deployVersion = localStorage?.getItem?.('deploy-version')
      if (deployVersion && deployVersion?.length > 50) {
        deployVersion = ''
        localStorage.setItem?.('deploy-version', deployVersion)
      }
      fetch('/build.txt')
        .then((response) => {
          if (response.ok) {
            return response.text()
          } else {
            Sentry.captureException(new Error('Failed to fetch data (extra response)'), {
              extra: {
                response,
                msg: 'in router.onError'
              }
            })
          }
        })
        .then((buildTxt) => {
          if (buildTxt && buildTxt?.length <= 50) {
            if (buildTxt !== deployVersion) {
              localStorage?.setItem?.('deploy-version', buildTxt)
              if (to?.fullPath && window?.location) {
                const connector = Object.keys(to.query).length > 0 ? '&' : '?'
                window.location.href = `${to.fullPath}${connector}__nc=${new Date().getTime()}`
              } else {
                location?.reload?.()
              }
            }
          } else {
            if (Sentry?.getClient()) {
              Sentry.captureException(new Error('not valid buildTxt'), {
                extra: {
                  to: to?.fullPath || window.location.href || location?.href,
                  buildTxt: buildTxt?.length ? buildTxt?.substring(0, 50) : buildTxt,
                  msg: 'not valid buildTxt in router.onError'
                }
              })
            }
          }
        })
        .catch((error) => {
          if (Sentry?.getClient()) {
            Sentry.captureException(error, {
              extra: {
                msg: 'catch error in router.onError'
              }
            })
          }
        })
    }
  })
  vue.use(router)
  vueRouter = router
  vue.use(VueGtag, {
    bootstrap: false,
    appName: 'Store - Gamesture',
    pageTrackerScreenviewEnabled: true,
    config: { id: import.meta.env.VITE_PROD ? 'G-ZCVJDJHNK2' : 'G-MNJS9M8EJW' }
  }, router)
}

export function initSsgRoutes() {
  const skeletonRoutes = [getSkeletonRoutes()[1]]
  skeletonRoutes[0].children = pagesRoutes().flatMap((route) => {
    if (route.ssg) {
      const ssgRoutes: RoutesData[] = [route]
      for (const ssgRoute of route.ssg) {
        const ssgRouteData = { ...route }
        ssgRouteData.meta = structuredClone(ssgRouteData.meta)
        if (ssgRouteData.meta.basicSeo) {
          ssgRouteData.meta.basicSeo.image = ssgRoute.image ?? ssgRouteData.meta.basicSeo.image ?? ''
          ssgRouteData.meta.basicSeo.defaultVariables = {
            ...(ssgRouteData.meta.basicSeo.defaultVariables ?? {}),
            ...(ssgRoute.defaultVariables ?? {})
          }
        }
        ssgRouteData.name = replaceTemplate(ssgRoute.name ?? '', ssgRouteData.meta?.basicSeo?.defaultVariables ?? {})
        ssgRouteData.path = replaceTemplate(ssgRoute.path ?? '', ssgRouteData.meta?.basicSeo?.defaultVariables ?? {})
        delete ssgRouteData.ssg
        ssgRoutes.push(ssgRouteData)
      }
      delete ssgRoutes[0].ssg
      return ssgRoutes
    }
    return route
  })
  return skeletonRoutes
}
export default {
  install: (app: App) => {
    vue = app
    initRouter()
  },
  getVueRouter() {
    return vueRouter
  }
}
