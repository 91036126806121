import type { RoutesData } from '@/router/routerInitPlugin'
import OgImg from '@/assets/og_image.jpeg?url'
import { gamesUtils } from '@/compositions/gamesUtils'

export default (): RoutesData[] => {
  return [
    {
      path: '/game/:gameID',
      name: 'game-page',
      component: () => import('@/pages/game/GamePage.vue'),
      meta: {
        basicSeo: {
          title: '{gameID} game  - Gamesture store',
          description: '{description}',
          image: OgImg,
          defaultVariables: {
            description: 'Gear up with The {gameID} game: Your One-Stop Mobile Gaming Shop!'
          }
        },
        bodyClass: ''
      },
      ssg: Object.entries(gamesUtils.list).map(([, gameData]) => {
        return {
          path: '/game/{idGame}',
          name: 'game-page-{idGame}',
          image: gameData.gameBanner,
          defaultVariables: {
            gameID: gameData.name,
            idGame: gameData.id,
            description: gameData.seoDescriptionGame
          }
        }
      })
    },
    {
      path: '/game/:gameID/offer/:offerID',
      name: 'game-product-page',
      component: () => import('@/pages/game/OfferPage.vue'),
      meta: {
        basicSeo: {
          title: 'Offer {gameID} game - Gamesture store',
          description: '{description}',
          image: OgImg,
          defaultVariables: {
            description: 'Exclusive In-Game Bundles Await!'
          }
        },
        bodyClass: ''
      },
      ssg: Object.entries(gamesUtils.list).map(([, gameData]) => {
        return {
          path: '/game/{idGame}/offer',
          name: 'game-product-page-{idGame}',
          image: gameData.gameBanner,
          defaultVariables: {
            gameID: gameData.name,
            idGame: gameData.id,
            description: gameData.seoDescriptionOffer
          }
        }
      })
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy-page',
      component: () => import('@/pages/info/PrivacyPolicy.vue'),
      meta: {
        basicSeo: {
          title: 'Privacy policy - Gamesture store',
          description: 'Privacy policy',
          image: OgImg
        },
        bodyClass: 'hide-cookies-bar'
      }
    },
    {
      path: '/terms-of-service',
      name: 'terms-of-service-page',
      component: () => import('@/pages/info/TermsOfService.vue'),
      meta: {
        basicSeo: {
          title: 'Terms of service - Gamesture store',
          description: 'Terms of service ',
          image: OgImg
        },
        bodyClass: 'hide-cookies-bar'
      }
    },
    {
      path: '/help',
      name: 'help-page',
      component: () => import('@/pages/info/HelpPage.vue'),
      meta: {
        basicSeo: {
          title: 'Help - Gamesture store',
          description: 'If you have any questions please contact us with email: help@gamesture.com',
          image: OgImg
        },
        bodyClass: ''
      }
    },
    {
      path: '/about',
      name: 'about-page',
      component: () => import('@/pages/info/AboutPage.vue'),
      meta: {
        basicSeo: {
          title: 'About - Gamesture store',
          description:
            'New to the store? How fun! Here at Gamesture Store, we\'ve created a new way to offer the best deals for in-game products to our players. Buy and share game passes with your old friends or donate them to new ones. Reward a newbie or give yourself an extra long boost.',
          image: OgImg
        },
        bodyClass: 'bg-white'
      }
    },
    {
      path: '/',
      name: 'home-page',
      component: () => import('@/pages/index/IndexPage.vue'),
      meta: {
        basicSeo: {
          title: 'Store - Gamesture',
          description: 'Gamesture Store: Elevate Your Gaming Experience, Master Your Fun!',
          image: OgImg
        },
        bodyClass: ''
      }
    }
  ]
}
