import routerInitPlugin from '@/router/routerInitPlugin'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import { createApp } from 'vue'
import App from './App.vue'
import axiosPlugin from './plugins/axiosPlugin'
import elPlusPlugin from './plugins/elPlusPlugin'
import globalComponents from './plugins/globalComponents'
import utilsPlugin from './plugins/utilsPlugin'
import vuexPlugin from './plugins/vuexPlugin'
import './css/index.css'

const head = createHead()
const Vue = createApp(App)
Vue.use(routerInitPlugin)
const router = routerInitPlugin.getVueRouter()
if (!import.meta.env.SSR
  && window?.location?.hostname
  && window?.location?.hostname !== 'localhost'
) {
  const isPWA = window?.matchMedia?.('(display-mode: standalone)')?.matches || false
  Sentry.init({
    Vue,
    dsn: 'https://ff3dc9e85dcdf37c6cb5d313b6c8d709@o48349.ingest.us.sentry.io/4508160978649088',
    beforeSend(event) {
      event.tags = {
        ...event.tags,
        isPWA: isPWA ? 'yes' : 'no'
      }
      return event
    },
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],
    environment: import.meta.env.VITE_PROD === 'on' ? 'production' : 'development',
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

Vue.use(head)
Vue.use(axiosPlugin)
Vue.use(elPlusPlugin)
Vue.use(utilsPlugin)
Vue.use(globalComponents)
Vue.use(vuexPlugin)
Vue.mount('#app')
